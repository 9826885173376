<template>

    <div>

    <b-modal
      ref="modalBanco"
      centered
      size="sm"
      hide-footer
      @hidden="closeModalRecargasBanco"
      :title="title"
    >
      <div class="d-block text-center">

  
          <b-row v-if="showBanks">

            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

          <b-avatar
                    size="25"
                    variant="light-primary"
                    style="margin:auto;background: #00838c !important;color: white !important;">

                    <span class="d-flex align-items-center">
                    1

                    </span>

                    </b-avatar>


            </b-col>
            <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
            
            <p style="text-align:left;margin:0px;">Seleccione el banco de su preferencia.</p>

            </b-col>


       

<b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px; margin-bottom: 10px;">


  <div class="radio-tile-group">
                 
    
          <div class="input-container" v-for="banco in banks" :key="banco.id">
                    <input :id="banco.id" class="radio-button" type="radio" @click="onChangeBanco(banco.id, banco.bank, banco.logo, banco.numberAccount, banco.titular, banco.ACH, banco.address, banco.email, banco.ruc, banco.typeAccount, banco.concepto)" :value="banco.id" name="radio" />
                    <div class="radio-tile">

                      <label class="radio-tile-label" style="margin-bottom: 6px;">{{banco.bank}}</label>
                

                   
                   
                    </div>
                  </div>

        
      
         </div>

  </b-col>



    


</b-row>


      <b-row v-else-if="showDetalles">

        <b-col cols="12" sm="12" md="12" lg="12" xl="12">

       

            <b-row>



              <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

                <b-avatar
                          size="25"
                          variant="light-primary"
                          style="margin:auto;background: #00838c !important;color: white !important;">

                          <span class="d-flex align-items-center">
                          2

                          </span>

                          </b-avatar>


                  </b-col>
                  <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
                  
                  <p style="text-align:left;margin:0px;">Realice una transferencia bancaria utilizando los siguientes datos:</p>

                  </b-col>

           


                  <b-col cols="12" sm="12" md="12" lg="12" xl="12"  style="margin-top:10px" ref="printMe">
         
                   

                    <div class="table-responsive" style="margin-bottom:10px">
                        <table class="table table-bordered" style="text-align: center;">

                        
                          <tbody>
                            <tr>
                                <td class="background_table" colspan="2">Datos para la transferencia</td>
                                </tr>
                            <tr>
                  
                              <td class="background_table">Banco:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{nameBank}}</p></td>
                            
                            </tr>


                              <tr>
                  
                              <td class="background_table">Nro. cuenta:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{numberAccount}}</p></td>
                            </tr>

                            <tr>

                            <td class="background_table">Titular:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{titular}}</p></td>
                            </tr>

                            <tr v-if="(ach != '')">
                            <td  class="background_table">ACH ROUTING NUMBER:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{ach}}</p></td>
                            </tr>

                            <tr v-if="(address != '')">
                            <td  class="background_table">Dirección:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{address}}</p></td>
                            </tr>

                            <tr v-if="(ruc != '')">
                            <td  class="background_table">Ruc:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{ruc}}</p></td>
                            </tr>

                            <tr v-if="(typeAccount != '')">
                            <td  class="background_table">Tipo de cuenta:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{typeAccount}}</p></td>
                            </tr>

                            

                            <tr v-if="(email != '')">
                            <td  class="background_table">Email:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{email}}</p></td>
                            </tr>

                    
                            <tr v-if="(concepto != '')">
                            <td  class="background_table">Concepto:</td>
                              <td class="background_td_table"><p style="font-size: 12px; margin:0px">{{concepto}}</p></td>
                            </tr>

                        

                    
                          
                          </tbody>

                        </table>
                        </div>

                       
                   
                  
                  </b-col>

                  <b-col  cols="12" sm="12" md="12" lg="12" xl="12"  >
                    <b-button @click="descargar" variant="primary" :disabled="isDisabledDescargar" style="margin-right: 15px;">

                      <feather-icon
                      icon="DownloadIcon"
                      size="16"
                      style="margin-right: 10px;"

                      /> 

                      Descargar

                      </b-button>

                      <b-button @click="shared" variant="outline-secondary" :disabled="isDisabledDescargar">

                          <feather-icon
                          icon="Share2Icon"
                          size="16"
                          style="margin-right: 10px;"


                          /> 

                          Compartir

                          </b-button>
                  </b-col>

                  <b-col  cols="12" sm="12" md="12" lg="12" xl="12"  >

                    <hr>

                    </b-col>

                   
            </b-row>

       

        </b-col>

        <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center" >
                        
                        <b-button block
                       
                                                  @click="atras1"
                                                  variant="outline-secondary"
                                                  style="margin-right: 15px;"
                                                >
                                                <feather-icon

                                                    icon="ArrowLeftCircleIcon"
                                                    size="13"
                                                    style= "margin-right:10px"
                                                    />
                                                  Atras 

                                                </b-button>

                      </b-col>
                         

                      <b-col
                      cols="6"
                                  sm="6"
                                  md="6"
                                  lg="6"
                                  xl="6"
                                  >

                                  <b-button
                                  block
                                  @click="siguiente"
                                      variant="primary"
                                  >

                                  Siguiente  <feather-icon  icon="ArrowRightCircleIcon" size="15" style="margin-left: 5px;"/> 

                                  </b-button>

                                  </b-col>

      


        </b-row>


        <b-row v-else>

          <b-col cols="12" sm="12" md="12" lg="12" xl="12">


            <b-row>

       


              <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

                <b-avatar
                          size="25"
                          variant="light-primary"
                          style="margin:auto;background: #00838c !important;color: white !important;">

                          <span class="d-flex align-items-center">
                          3

                          </span>

                          </b-avatar>


                  </b-col>
                  <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
                  
                  <p style="text-align:left;margin:0px;">Complete el siguiente formulario:</p>

                  </b-col>

                  <b-form
                            autocomplete="off"
                            @submit.prevent="onSubmitBanco"
                            
                        >

                        <b-row>


                  <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:15px" >

                    <p class="textLabel" >Ingrese el nro. de comprobante (Solo números *):</p>

                    <b-form-input
                    @blur="isNumberBlur"
                    @keypress="NumbersOnly"
                     type="tel"
                      v-model="referencia"
                          placeholder="Ingrese el número de comprobante"
                          required
                          autofocus
                          :readonly="isDisabled"
                      />

                      
                     

                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12"  style="margin-top: 10px;">
                      <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                                
                                  <p style="text-align: left;font-size:13px; margin-bottom:0px">En el caso de que su referencia contenga caracteres especiales o alfabéticos, comuníquese con servicio al cliente para realizar su recarga.</p>

                     
                        </div>
                      </b-alert>
                    </b-col>

                    <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        style="margin-top: 10px;"
                      >
                      <p class="textLabel" >Suba la imagen de la transferencia (*):</p>
                      
                        <b-form-file
                          id="depositoFoto"
                          v-model="depositoFoto"
                          required
                          :disabled="isDisabled"
                          accept="image/jpeg, image/jpg, image/png"
                          placeholder="Seleccione una imagen o suéltelo aquí..."
                          drop-placeholder="Suelte la imagen aqui..."
                          @change="previewImage1"
                        />

                        <p style="margin: 0;font-size: 12px;display:flex">{{ uploadValue1.toFixed()+"%" }}
                          <progress
                            style="width:100%"
                            :value="uploadValue1"
                            max="100"
                          />  </p>

                      </b-col>


                    <b-col
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            style="margin-top:10px"
                            >

                        

                            <p
                                class="textLabel"
                                style="text-align: center;margin-top: 0px;margin-bottom: 10px;"
                            >
                                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                                <PincodeInput
                                    v-model="pin"
                                    placeholder="-"
                                    :length="6"
                                    :autofocus="false"
                                    :secure="true"
                                    :disabled="isDisabled"
                                
                                    required
                                />

                                </b-col>

                                <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center" >
                        
                                  <b-button block
                                  :disabled="isDisabled"
                                                            @click="atras2"
                                                            variant="outline-secondary"
                                                            style="margin-right: 15px;"
                                                          >
                                                          <feather-icon

                                                              icon="ArrowLeftCircleIcon"
                                                              size="13"
                                                              style= "margin-right:10px"
                                                              />
                                                            Atras 

                                                          </b-button>

                                </b-col>
                                   

                                <b-col
                                cols="6"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                            >

                                            <b-button
                                            block
                                                id="btn_solicitar"
                                                ref="btn_solicitar"
                                                type="submit"
                                                variant="primary"
                                                :disabled="isDisabled"
                                            >

                                            Enviar

                                            </b-button>

                                            </b-col>


                                          </b-row>


                                          </b-form>

               

            </b-row>
            

        </b-col>



          </b-row>



        </div>


        </b-modal>
  
  
  </div>
  
  </template>
  
  <script>
  import {
   BButton, BRow, BCol, BCard, BForm, BAlert,  VBTooltip,VBPopover,BAvatar,BFormInput,BFormFile
  } from 'bootstrap-vue'

  import PincodeInput from 'vue-pincode-input'
  import domtoimage from "dom-to-image-more";

import 'firebase/compat/storage'

  export default {
    components: {
      BButton,
      BFormFile,
      BForm,
      BFormInput,
      BAvatar,
      BRow,
      BCol,
      BAlert,
      BCard,
      PincodeInput,
      domtoimage
    },
    props: ['userId','tokenAuth', 'banks'],
    directives: {
            'b-popover': VBPopover,
      'b-tooltip': VBTooltip,
    },
    data() {
  
                          
  
      return {



        title:"Recargar por transferencia bancaria",
       isDisabled:false,
       pin:"",
       showBanks:true,
       showDetalles:false,
       bankId:"",
       nameBank:"",
       logoBank:"",
       numberAccount:"",
       titular:"",
       depositoFoto: [],
       uploadValue1: 0,
       ach:"",
       address:"",
       typeAccount:"",
       email:"",
       concepto:"",
       ruc:"",
       isDisabledDescargar:false,
       referencia:"",


      
      }
    },
    computed: {
  
  
  
    },
    watch: {
  
  
  
    },
    mounted() {
  
  
       

        this.$refs.modalBanco.show()
  
        
  
  
  
    },
    methods: {

      previewImage1(event) {
      const FileSizeBanner = event.target.files[0].size / 1024 / 1024

      if (FileSizeBanner > 3) {
        this.depositoFoto = [];

        this.$toast.error('La imagen debe pesar menos de 3mb', {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      }
    },


    siguiente(){

this.referencia="";
this.pin="";
this.depositoFoto=null;
       this.uploadValue1=0,
this.showDetalles=false;
this.showBanks=false;
},

    atras1(){


this.showDetalles=false;
this.showBanks=true;
},

      atras2(){


    this.showDetalles=true;
    this.showBanks=false;
    },

    async shared(){

        const node = this.$refs.printMe;

        const scale = 750 / node.offsetWidth;



          let base64= await domtoimage
                .toPng(node, {
                    quality: 1,
                    height: node.offsetHeight * scale,
                    width: node.offsetWidth * scale,
                    style: {
                        transform: "scale(" + scale + ")",
                        transformOrigin: "top left",
                        width: node.offsetWidth + "px",
                        height: node.offsetHeight + "px"
                    }})
                .then(function (dataUrl) {

                    return dataUrl;


        
                });


            fetch(base64).then(function (response) {
            return response.blob()
            }).then(function (blob) {
            let file = new File([blob], "Datos_deposito.png", {type: 'image/png'});
            let filesArray = [file];
            if (navigator.canShare && navigator.canShare({files: filesArray})) {
                navigator.share({
                files: filesArray
                }).then(() => console.log('Successful share'))
                .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                }));
            }else{

                this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                })

            }
            });




            },

        async descargar(){



            const node = this.$refs.printMe;


            const scale = 750 / node.offsetWidth;


            this.isDisabledDescargar=true;


          let base64= await domtoimage
                .toPng(node,{
                            quality: 1,
                    height: node.offsetHeight * scale,
                    width: node.offsetWidth * scale,
                    style: {
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top left",
                    width: node.offsetWidth + "px",
                    height: node.offsetHeight + "px"
                    }})
                .then(function (dataUrl) {

                    return dataUrl;


        
                })
                .catch(function (error) {
                console.error("oops, something went wrong!", error);
                });

          
                var link = document.createElement("a");
                    link.download = "Datos_deposito_"+this.nameBank+".png";
                    link.href = base64;
                    link.click();
                    this.isDisabledDescargar=false;



            },

      onChangeBanco(id, nameBank, logo, numberAccount, titular, ach, address, email, ruc,typeAccount, concepto){

        this.bankId=id;
        this.nameBank=nameBank;
        this.logoBank= logo;
        this.numberAccount=numberAccount;
        this.titular=titular;
        this.ach=ach;
        this.address=address;
        this.email=email;
        this.concepto=concepto;
        this.ruc=ruc;
        this.typeAccount=typeAccount;
        this.showBanks=false;
        this.showDetalles=true;


      },

        closeModalRecargasBanco(){

     
         
            this.$eventBus.$emit('reiniciarRecargasBanco');
    
           this.$eventBus.$emit('reiniciarRecargarBancoHome')


    },
    isNumberBlur(){


      if(this.referencia === null || this.referencia === ""){

        this.referencia="";


        }else{

 
          if(isNaN(this.referencia)){

            this.$toast.error("El campo referencia solo permite valores numéricos", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
              this.referencia="";

          }



        }
  


    },
    NumbersOnly(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    onSubmitBanco(event) {
      event.preventDefault()

      this.isDisabled = true
      this.uploadValue1 = 0;

      const d1 = new Date();

      let nameFile= this.userId+"_"+d1.getTime()+"_"+this.depositoFoto.name;
      let dir= 'depositosBancarios/'+this.userId;

        const storageRef = this.$appFirebase.storage().ref(dir).child(`${nameFile}`).put(this.depositoFoto);

      storageRef.on('state_changed', snapshot => {
        this.uploadValue1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

        document.getElementById('btn_solicitar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Subiendo'
      }, error => {
        this.$toast.error("Ocurrió un error inesperado".message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        this.isDisabled = false
        document.getElementById('btn_solicitar').innerHTML = 'Enviar'
      },
      () => {
        this.uploadValue1 = 100
        storageRef.snapshot.ref.getDownloadURL().then(url => {
          const data_json = { userId: this.userId,  pin: this.pin, referencia: this.referencia, bankId:this.bankId,nameBank: this.nameBank }
          const data_string = JSON.stringify(data_json)

          const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

          document.getElementById('btn_solicitar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Enviando'

          this.$https.post('/profile/sendDeposito/', { tokenAuth: this.tokenAuth,url: url, data: dataEncripted }).then(response => {
            document.getElementById('btn_solicitar').innerHTML = 'Enviar';
            this.isDisabled = false
            if (response.data.code == 200) {
             

                  this.$toast.success(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                });

                this.$refs.modalBanco.hide();
                
                this.$eventBus.$emit('reiniciarListadoBanco');
                this.$eventBus.$emit('reiniciarRecargarBancoHome');
          
              
            } else {
              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

              if (response.data.code == 401) {
                this.$refs.modalPhoto.hide()

                
                localStorage.removeItem('userData')

                

                
                

           

                
                

                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              }else{

                if( response.data.code==407){

                  this.$refs.modalBanco.hide();

                }else{

                  if(response.data.code == 406){
             

                                        
                      this.$refs.modalBanco.hide();

                        this.$swal({
                                  title: 'No tienes registrado una cédula/DNI a tu perfil',
                                  text: `¿Deseas completar tu perfil ahora?`,
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonText: 'Registrar',
                                  cancelButtonText: 'No',
                                  customClass: {
                                    confirmButton: 'btn btn-primary',
                                    cancelButton: 'btn btn-outline-secondary ml-1',
                                  },
                                  buttonsStyling: false,
                                }).then(result => {
                                  if (result.value) {
                                    this.$router.push({ name: 'perfil', hash: '#general' })

                                  }
                                })
          

                      }

                }
               
              }
            }
          }).catch(error => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
        })
      })
    },
        

    
    }
  }
  </script>
  
  <style lang="scss">
  
  
  
  
  
  </style>
  